@import "static/styles/colors";
@import "static/styles/constants";
@import "static/styles/functions";

.saiu-na-midia {
	display: flex;
	flex-direction: column;
	align-items: center;

	.title {
		font-size: $font-size-xxxl;
		margin: 128px $spacing-md $spacing-xxxl $spacing-md;

		@media (max-width: $max-width-md) {
			font-size: $font-size-xl;
			margin: $spacing-xxxl $spacing-md $spacing-xxl $spacing-md;
		}
	}

	.content {
		display: flex;
		flex-direction: column;

		.article + .article {
			margin-top: $spacing-xxxl;
		}

		.article {
			margin: $spacing-xl $spacing-md;

			.article-source {
				color: $color-interface-dark-up;
				font-family: $font-family-highlight;
				font-size: $font-size-xxs;
				font-weight: $font-weight-bold;
				line-height: $line-height-xxs;
			}

			.article-title {
				margin: 10px 0;
				color: $color-interface-dark-deep;
				font-family: $font-family-highlight;
				font-size: $font-size-sm;
				font-weight: $font-weight-bold;
				line-height: $line-height-sm;
			}

			.article-description {
				color: $color-interface-dark-deep;
				font-size: $font-size-xs;
				font-weight: $font-weight-medium;
				line-height: $line-height-xs;
			}

			.article-button {
				margin-top: $spacing-xl;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: $spacing-xs $spacing-sm;
				gap: $spacing-xxs;
				border-radius: $spacing-md;
				background: $color-brand-primary-up;
				width: max-content;
				cursor: pointer;

				span {
					color: $color-brand-primary-pure;
					font-weight: $font-weight-bold;
					font-family: $font-family-highlight;
					font-size: $font-size-xs;
					line-height: $line-height-xs;
				}
			}
		}
	}
}
